import React, { FC } from 'react'
import { HashLink } from 'react-router-hash-link'

type AboutUsProps = {
  topTile: string
  title: string
  description: string
}

const AboutUs: FC<AboutUsProps> = ({ topTile, title, description }) => {
  return (
    <section className="about-us-section ptb-100">
      <div id="about" className="container">
        <div className="row align-items-center">
          <div className="col-lg-6" data-cues="slideInLeft">
            <div className="about-us-img">
              <img
                className="about-img-1"
                src="assets/images/about/saa-about-img-1-2.png"
                alt="about-img-1"
                width="445px"
                height="530px"
              />
              <div className="about-img-2">
                <div className="video-btn-wrap">
                  <img
                    src="assets/images/about/saa-about-img-2.jpeg"
                    alt="about-img-2"
                    width="445px"
                    height="530px"
                  />
                  <div className="video-btn">
                    <a
                      href="https://www.youtube.com/watch?v=bk7McNUjWgw"
                      className="popup-youtube"
                    >
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <div className="icon">
                        <i className="fa-solid fa-play"></i>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <img
                className="about-shape"
                src="assets/images/about/about-shape.png"
                alt="About Shape"
              />
            </div>
          </div>

          <div className="col-lg-6" data-cues="slideInRight">
            <div className="about-us-content">
              <span className="top-title">{topTile}</span>
              <h2>{title}</h2>
              <p>{description}</p>

              <div className="row justify-content-center">
                <div className="col-lg-6 col-sm-6">
                  <div className="about-item d-flex align-items-center">
                    <img src="assets/images/icon/skills.png" alt="skills" />
                    <h3>Expertise comptable</h3>
                  </div>
                </div>

                <div className="col-lg-6 col-sm-6">
                  <div className="about-item d-flex align-items-center">
                    <img src="assets/images/icon/urgent.png" alt="urgent" />
                    <h3>Audit</h3>
                  </div>
                </div>
              </div>

              <HashLink to="/#contact" className="main-btn">
                <span>
                  Nous contacter
                  <i className="fa-solid fa-arrow-right"></i>
                </span>
              </HashLink>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutUs
