import React, { FC } from 'react'
import { HashLink } from 'react-router-hash-link'

const Header: FC = () => {
  return (
    <section className="header-section header-bg-style bg-color-161735">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-8">
            <ul className="header-left-content">
              <li>
                <HashLink to="/#">
                  <i className="fa-light fa-location-dot"></i>
                  Sanoussy Associés - SAA
                </HashLink>
              </li>
              <li>
                <a href="mailto:contact@sanoussyassocies.com">
                  <i className="fa-light fa-envelope"></i>
                  contact@sanoussyassocies.com
                </a>
              </li>
              <li>
                <a href="tell:+1-646-980-4741">
                  <i className="fa-light fa-phone"></i>
                  +224 655 77 24 24
                </a>
              </li>
            </ul>
          </div>
          <div className="col-lg-4">
            <ul className="header-right-content">
              <li>
                <HashLink to="#" className="hover-style">
                  <span className="inner-border">
                    <i className="fa-brands fa-facebook-f"></i>
                  </span>
                </HashLink>
              </li>
              <li>
                <HashLink to="#" className="hover-style">
                  <span className="inner-border">
                    <i className="fa-brands fa-instagram"></i>
                  </span>
                </HashLink>
              </li>
              <li>
                <HashLink to="#" className="hover-style">
                  <span className="inner-border">
                    <i className="fa-brands fa-twitter"></i>
                  </span>
                </HashLink>
              </li>
              <li>
                <HashLink to="#" className="hover-style">
                  <span className="inner-border">
                    <i className="fa-brands fa-youtube"></i>
                  </span>
                </HashLink>
              </li>
              <li>
                <HashLink to="#" className="hover-style">
                  <span className="inner-border">
                    <i className="fa-brands fa-linkedin-in"></i>
                  </span>
                </HashLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Header
