import React, { FC } from 'react'
import Banner from 'src/components/Banner/Banner copy'
import Header from 'src/components/Header/Header'
import Navbar from 'src/components/Navbar/Navbar'
import './TeamPage'

const TeamPage: FC = () => {
  const s1 = document.createElement('script')
  s1.src = 'assets/js/jquery.min.js'
  s1.async = true

  const s2 = document.createElement('script')
  s2.src = 'assets/js/bootstrap.bundle.min.js'
  s2.async = true

  const s3 = document.createElement('script')
  s3.src = 'assets/js/jquery.meanmenu.js'
  s3.async = true

  const s4 = document.createElement('script')
  s4.src = 'assets/js/owl.carousel.min.js'
  s4.async = true

  const s5 = document.createElement('script')
  s5.src = 'assets/js/magnific-popup.min.js'
  s5.async = true

  const s6 = document.createElement('script')
  s6.src = 'assets/js/counterup.min.js'
  s6.async = true

  const s7 = document.createElement('script')
  s7.src = 'assets/js/waypoints.min.js'
  s7.async = true

  const s8 = document.createElement('script')
  s8.src = 'assets/js/scrollcue.js'
  s8.async = true

  const s9 = document.createElement('script')
  s9.src = 'assets/js/countdown.min.js'
  s9.async = true

  const s10 = document.createElement('script')
  s10.src = 'assets/js/custom.js'
  s10.async = true

  document.body.appendChild(s1)
  document.body.appendChild(s2)
  document.body.appendChild(s3)
  document.body.appendChild(s4)
  document.body.appendChild(s5)
  document.body.appendChild(s6)
  document.body.appendChild(s7)
  document.body.appendChild(s8)
  document.body.appendChild(s9)
  document.body.appendChild(s10)
  return (
    <>
      <Header />
      <Navbar />
      <Banner />
      <section
        className="team-section ptb-100 bg-img"
        data-background="./assets/images/team/team-bg-2.jpg"
      >
        <div className="container" id="team-member">
          <div className="main-section-title">
            <span className="top-title">NOTRE EQUIPE</span>
            <h2>Nos experts sont là pour répondre à vos besoins</h2>
          </div>

          <div className="row justify-content-center">
            <div className="col-6">
              <div className="main-team-item" id="mamadicamara">
                <img
                  src="assets/images/team/team-img-2.png"
                  height="210"
                  width="210"
                  alt="CEO"
                />
                <h3>Mamadi CAMARA</h3>
                <span>Expert Comptable</span>
                <div style={{ textAlign: 'start', minHeight: 300 }}>
                  <p>
                    Mamadi CAMARA est titulaire du diplôme d’expertise comptable
                    obtenu en France. Il est inscrit à l’Ordre des
                    experts-comptables de Paris et à la Compagnie Régionale des
                    commissaires aux comptes de Paris, Ile de France. Monsieur
                    CAMARA est en cours d’inscription auprès de l’Ordre des
                    experts-comptables de la République de Guinée.
                  </p>
                  <p>
                    Après vingt (20) ans d’expérience, au sein des cabinets
                    d’audit et d’expertise comptable à l’international et en
                    France, il a créé le cabinet SANOUSSY ASSOCIES pour exercer
                    pleinement les métiers de la profession du chiffre.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="main-team-item" id="ismaelconde">
                <img
                  src="assets/images/team/team-img-2.png"
                  height="210"
                  width="210"
                  alt="Manager"
                />
                <h3>Ismaël CONDE</h3>
                <span>Manager</span>
                <div style={{ textAlign: 'start', minHeight: 300 }}>
                  <p>
                    Manager, Monsieur Ismaël CONDE est titulaire d’un master II
                    (BAC+5), en finance, audit et contrôle de gestion, à l’ISEEC
                    de Lyon, en France.
                  </p>
                  <p>
                    Il dispose d’une expérience de plus de 8 ans en audit et
                    contrôle de gestion en cabinet d’audit et au sein des
                    entités évoluant dans les secteurs bancaires et de
                    télécommunications.
                  </p>
                  <p>
                    Monsieur CONDE a participé aux missions d’organisation et de
                    tenue de la comptabilité des sociétés et des établissement
                    public. A l’audit des comptes annuels, d’audit interne et de
                    mise en place de procédures pour sécuriser leurs activités.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-6">
              <div className="main-team-item" id="famorocamara">
                <img
                  src="assets/images/team/team-img-2.png"
                  height="210"
                  width="210"
                  alt="consultant"
                />
                <h3>Famoro CAMARA</h3>
                <span>Consultant secteur pétrolier</span>
                <div style={{ textAlign: 'start', minHeight: 300 }}>
                  <p>
                    Est titulaire d’une maîtrise en gestion (UGAN, Conakry), et
                    du Diplôme de Comptabilité et de Gestion obtenu en France.
                    Il dispose d’une expérience professionnelle de plus de 17
                    ans acquise en Guinée et en France.
                  </p>
                  <p>
                    Il a assumé les fonctions de Contrôle de Gestion, de gestion
                    administrative et comptable auprès du groupe TOTAL. De
                    Directeur, d’entreprises publiques, chargé de la
                    surveillance et de la réglementation du secteur pétrolier
                    guinéen. Il intervient, à titre de consultant, auprès des
                    sociétés industrielles Françaises, pour la mise en place et
                    la supervision de la gestion administrative et comptable.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="main-team-item" id="mohamedcamara">
                <img
                  src="assets/images/team/team-img-2.png"
                  height="210"
                  width="210"
                  alt="superviseur"
                />
                <h3>Mohamed KABA</h3>
                <span>Superviseur</span>
                <div style={{ textAlign: 'start', minHeight: 300 }}>
                  <p>
                    Superviseur, Monsieur Mohamed KABA est diplômé d’une
                    Maîtrise (BAC+4) en économie et gestion à l’Université Kofi
                    ANNAN, Conakry, République de Guinée. Il dispose d’une
                    expérience de plus de 10 ans dans le domaine de la
                    comptabilité, de l’audit, de la fiscalité, des ressources
                    humaines et de passation de Marchés.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default TeamPage
