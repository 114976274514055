import React, { FC, useState } from 'react'
import emailjs from '@emailjs/browser'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import './Contact.css'

const Contact: FC = () => {
  const serviceId = 'service_rwt03sf'
  const templateId = 'template_577hpgb'

  const [notification, setNotification] = useState(null)

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      tel: '',
      subjet: '',
      message: '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(3, 'Le nom est trop court')
        .required('Le nom est obligatoire'),
      tel: Yup.string().required('Le Numéro de Téléphone est obligatoire'),
      email: Yup.string()
        .email('Cet email est invalide')
        .required('E-mail obligatoire'),
      message: Yup.string()
        .min(3, 'Message trop court')
        .required('Message obligatoire'),
    }),
    onSubmit: async (values) => {
      try {
        setNotification(null)
        emailjs.init('tbsso0slZ_rHA4d97')
        await emailjs.send(serviceId, templateId, {
          to_name: 'SAA',
          from_email: values.email,
          from_name: values.name,
          from_tel: values.tel,
          subjet: values.subjet,
          message: values.message,
        })
        setNotification('Message envoyé avec SUCESS !')
      } catch (error) {
        setNotification("Impossible d'envoyer le message réssayer plus tard !")
      }
    },
  })

  const onChange = (e) => {
    setNotification(null)
    formik.handleChange(e)
  }

  return (
    <section className="contact-form-section ptb-100" id="contact">
      <div className="container">
        <div className="main-section-title">
          <span className="top-title">NOUS CONTACTER</span>
          <h2>Nous écrire</h2>
        </div>

        <div className="contact-form-content">
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-lg-6 col-sm-6">
                <div className="form-group">
                  <input
                    className="form-control"
                    id="name"
                    name="name"
                    placeholder="Votre Nom"
                    type="text"
                    onChange={onChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                  />

                  {formik.touched.name && formik.errors.name ? (
                    <span className="form-error">{formik.errors.name}</span>
                  ) : null}
                </div>
              </div>
              <div className="col-lg-6 col-sm-6">
                <div className="form-group">
                  <input
                    className="form-control"
                    id="email"
                    name="email"
                    onChange={onChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    placeholder="Votre Email "
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <span className="form-error">{formik.errors.email}</span>
                  ) : null}
                </div>
              </div>
              <div className="col-lg-6 col-sm-6">
                <div className="form-group">
                  <input
                    className="form-control"
                    id="tel"
                    name="tel"
                    onChange={onChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.tel}
                    placeholder="Votre numéro de téléphone"
                  />
                  {formik.touched.tel && formik.errors.tel ? (
                    <span className="form-error">{formik.errors.tel}</span>
                  ) : null}
                </div>
              </div>
              <div className="col-lg-6 col-sm-6">
                <div className="form-group">
                  <input
                    className="form-control"
                    id="subjet"
                    name="subjet"
                    placeholder="Objet"
                    onChange={onChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.subjet}
                  />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group">
                  <textarea
                    className="form-control"
                    id="message"
                    name="message"
                    onChange={onChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.message}
                    cols={20}
                    rows={10}
                    placeholder="Votre Message "
                  ></textarea>
                  {formik.touched.message && formik.errors.message ? (
                    <span className="form-error">{formik.errors.message}</span>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-12">
                <button type="submit" className="main-btn">
                  <span>
                    Envoyer
                    <i className="fa-solid fa-arrow-right"></i>
                  </span>
                </button>
              </div>
            </div>
          </form>
          {notification && (
            <span className="notification-sucess">{notification}</span>
          )}
        </div>
      </div>
    </section>
  )
}

export default Contact
