import React, { useEffect } from 'react'

import './App.css'

import HomePage from 'src/pages/HomePage'
import TeamPage from 'src/pages/TeamPage'
import ServicesPage from 'src/pages/ServicesPage'
import { HashRouter as Router, Route, Routes } from 'react-router-dom'

const App = () => {
  useEffect(() => {
    const s1 = document.createElement('script')
    s1.src = 'assets/js/jquery.min.js'
    s1.async = true

    const s2 = document.createElement('script')
    s2.src = 'assets/js/bootstrap.bundle.min.js'
    s2.async = true

    const s3 = document.createElement('script')
    s3.src = 'assets/js/jquery.meanmenu.js'
    s3.async = true

    const s4 = document.createElement('script')
    s4.src = 'assets/js/owl.carousel.min.js'
    s4.async = true

    const s5 = document.createElement('script')
    s5.src = 'assets/js/magnific-popup.min.js'
    s5.async = true

    const s6 = document.createElement('script')
    s6.src = 'assets/js/counterup.min.js'
    s6.async = true

    const s7 = document.createElement('script')
    s7.src = 'assets/js/waypoints.min.js'
    s7.async = true

    const s8 = document.createElement('script')
    s8.src = 'assets/js/scrollcue.js'
    s8.async = true

    const s9 = document.createElement('script')
    s9.src = 'assets/js/countdown.min.js'
    s9.async = true

    const s10 = document.createElement('script')
    s10.src = 'assets/js/custom.js'
    s10.async = true

    document.body.appendChild(s1)
    document.body.appendChild(s2)
    document.body.appendChild(s3)
    document.body.appendChild(s4)
    document.body.appendChild(s5)
    document.body.appendChild(s6)
    document.body.appendChild(s7)
    document.body.appendChild(s8)
    document.body.appendChild(s9)
    document.body.appendChild(s10)
  }, [])

  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
      </Routes>
      <Routes>
        <Route path="/home" element={<HomePage />} />
      </Routes>
      <Routes>
        <Route path="/team" element={<TeamPage />} />
      </Routes>
      <Routes>
        <Route path="/services" element={<ServicesPage />} />
      </Routes>
    </Router>
  )
}

export default App
