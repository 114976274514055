import React, { FC } from 'react'
import { HashLink } from 'react-router-hash-link'

const Team: FC = () => {
  return (
    <section
      className="testimonial-section ptb-100 bg-img"
      data-background="./assets/images/testimonials/testimonial-bg.jpg"
    >
      <div className="container">
        <div className="main-section-title white-title">
          <span className="top-title">NOTRE EQUIPE</span>
          <h2>Nos experts pour répondre à vos besoins</h2>
        </div>

        <div className="row justify-content-center">
          <div className="col-lg-3 col-sm-6">
            <div className="main-team-item">
              <img
                src="assets/images/team/team-member.jpeg"
                height="210"
                width="210"
                alt="CEO"
              />
              <h3>Mamadi CAMARA</h3>
              <span>Expert Comptable</span>
              <HashLink to="/team#mamadicamara" className="main-btn">
                <span>
                  Plus de détails
                  <i className="fa-solid fa-arrow-right"></i>
                </span>
              </HashLink>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="main-team-item">
              <img
                src="assets/images/team/team-member.jpeg"
                height="210"
                width="210"
                alt="CEO"
              />
              <h3>Ismaël CONDE</h3>
              <span>Manager</span>
              <HashLink to="/team#ismaelconde" className="main-btn">
                <span>
                  Plus de détails
                  <i className="fa-solid fa-arrow-right"></i>
                </span>
              </HashLink>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="main-team-item">
              <img
                src="assets/images/team/team-member.jpeg"
                height="210"
                width="210"
                alt="CEO"
              />
              <h3>Mohamed KABA</h3>
              <span>Superviseur</span>
              <HashLink to="/team#mohamedcamara" className="main-btn">
                <span>
                  Plus de détails
                  <i className="fa-solid fa-arrow-right"></i>
                </span>
              </HashLink>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="main-team-item">
              <img
                src="assets/images/team/team-member.jpeg"
                height="210"
                width="210"
                alt="CEO"
              />
              <h3>Famoro CAMARA</h3>
              <span>Consultant</span>
              <HashLink to="/team#famorocamara" className="main-btn">
                <span>
                  Plus de détails
                  <i className="fa-solid fa-arrow-right"></i>
                </span>
              </HashLink>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Team
