import React, { FC } from 'react'
import CompagnyService from '../CompagnyServive/CompagnyService'
import { CompagnyServiceType } from 'src/type/type'

type CompagnyProps = {
  title: string
  description: string
}

const Compagny: FC<CompagnyProps> = ({ title, description }) => {
  const services: CompagnyServiceType[] = [
    {
      imageSrc: 'assets/images/icon/quality.png',
      title: 'Service de qualité',
      description:
        'Souci de vous apporter des prestations de qualité et fiable',
      alter: 'quality',
    },
    {
      imageSrc: 'assets/images/icon/team.png',
      title: 'Equipe Dynamique',
      description: 'une équipe dynamique à votre service',
      alter: 'team',
    },
    {
      imageSrc: 'assets/images/icon/support.png',
      title: 'Equipe Compétente',
      description: 'Une réponse adaptée à vos besoins',
      alter: 'support',
    },

    {
      imageSrc: 'assets/images/icon/management.png',
      title: 'Accompagnement',
      description: 'Vous accompagnez dans l’atteinte de vos objectifs',
      alter: 'management',
    },
  ]

  return (
    <section
      className="company-section bg-img pt-100 pb-70 bg-color-f8f8f9"
      data-background="./assets/images/company-bg-shape.png"
    >
      <div className="container">
        <div className="main-section-title">
          <span className="top-title">{title}</span>
          <h2>{description}</h2>
        </div>

        <div className="row justify-content-center" data-cues="slideInDown">
          {services.map((service, index) => (
            <CompagnyService {...service} key={index} />
          ))}
        </div>
      </div>
    </section>
  )
}
export default Compagny
