import React, { FC } from 'react'

const FAQ: FC = () => {
  return (
    <section
      className="faq-section ptb-100 bg-img"
      data-background="./assets/images/faq/faq-bg.jpg"
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="faq-content" data-cues="slideInLeft">
              <span className="top-title">QUELQUES ELEMENTS DE REPONSE</span>
              <h2>Voulez-vous savoir pourquoi choisir SANOUSSY ASSOCIES ?</h2>

              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h3 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Pour notre savoir-faire
                    </button>
                  </h3>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>Appréhender vos besoins pour mieux vous accompagner</p>
                      <p>Une analyse appropriée de votre situation</p>
                      <p>Une réponse adaptée à vos besoins</p>
                      <p>Un apport réel de valeur ajoutée</p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h3 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      Pour notre engagement
                    </button>
                  </h3>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>
                        Vous garantir un partenariat durable et porteur de
                        valeur sûre et réelle
                      </p>
                      <p>
                        Le sens de responsabilité et d’indépendance de nos
                        experts intervenants
                      </p>
                      <p>
                        La qualité des prestations garanties pour le respect de
                        nos codes de déontologie et nos normes de travail
                      </p>
                      <p>
                        La proximité et la possible intervention rapide de nos
                        équipes selon vos disponibilités
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h3 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Pour nos services
                    </button>
                  </h3>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>
                        Diverses prestations proposées pour satisfaire vos
                        attentes
                      </p>
                      <p>
                        Des services diversifiés et en phase avec l’évolution
                        des besoins clients
                      </p>
                      <p>
                        Une dynamique vers une spécialisation sur les domaines
                        d’intervention des clients
                      </p>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h3 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      Pour nos valeurs
                    </button>
                  </h3>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>
                        Satisfaction client au centre de nos préoccupations et
                        compréhension de vos besoins
                      </p>
                      <p>
                        Souci de vous apporter des prestations de qualité et
                        fiable
                      </p>
                      <p>
                        Votre réussite est notre objectif, stimulée par la
                        proximité de nos intervenants
                      </p>
                      <p>
                        Vous accompagnez dans l’atteinte de vos objectifs au
                        travers des interventions adaptées et réalisées avec des
                        compétences approuvées
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="faq-img" data-cues="slideInRight">
              <img
                src="assets/images/faq/saa-faq-img2.jpg"
                alt="faq-img"
                width="860px"
                height="850px"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FAQ
