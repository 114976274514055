import React, { FC } from 'react'
import AboutUs from 'src/components/AboutUs/AboutUs'
import Analysis from 'src/components/Analysis/Analysis'
import Banner from 'src/components/Banner/Banner copy'
import Blog from 'src/components/Blog/Blog'
import CompleteSection from 'src/components/CompleteSection/CompleteSection'
import Contact from 'src/components/Contact/Contact'
import FAQ from 'src/components/FAQ/FAQ'
import Header from 'src/components/Header/Header'
import Navbar from 'src/components/Navbar/Navbar'
import Service from 'src/components/Service/Service'
import Team from 'src/components/Team'
import Compagny from 'src/components/compagny/Compagny'

const HomePage: FC = () => {
  const s1 = document.createElement('script')
  s1.src = 'assets/js/jquery.min.js'
  s1.async = true

  const s2 = document.createElement('script')
  s2.src = 'assets/js/bootstrap.bundle.min.js'
  s2.async = true

  const s3 = document.createElement('script')
  s3.src = 'assets/js/jquery.meanmenu.js'
  s3.async = true

  const s4 = document.createElement('script')
  s4.src = 'assets/js/owl.carousel.min.js'
  s4.async = true

  const s5 = document.createElement('script')
  s5.src = 'assets/js/magnific-popup.min.js'
  s5.async = true

  const s6 = document.createElement('script')
  s6.src = 'assets/js/counterup.min.js'
  s6.async = true

  const s7 = document.createElement('script')
  s7.src = 'assets/js/waypoints.min.js'
  s7.async = true

  const s8 = document.createElement('script')
  s8.src = 'assets/js/scrollcue.js'
  s8.async = true

  const s9 = document.createElement('script')
  s9.src = 'assets/js/countdown.min.js'
  s9.async = true

  const s10 = document.createElement('script')
  s10.src = 'assets/js/custom.js'
  s10.async = true

  document.body.appendChild(s1)
  document.body.appendChild(s2)
  document.body.appendChild(s3)
  document.body.appendChild(s4)
  document.body.appendChild(s5)
  document.body.appendChild(s6)
  document.body.appendChild(s7)
  document.body.appendChild(s8)
  document.body.appendChild(s9)
  document.body.appendChild(s10)
  return (
    <>
      <Header />
      <Navbar />
      <Banner />
      <Compagny
        title="NOTRE SAVOIR-FAIRE"
        description="Appréhender vos besoins pour mieux vous accompagner"
      />
      <AboutUs
        topTile="QUI SOMMES NOUS ?"
        title="Cabinet d’expertise comptable et de l’audit"
        description="SANOUSSY ASSOCIES est une société d’expertise comptable et de
                l’audit. Notre cabinet s’adresse aux entités et organismes
                privés et publics"
      />
      <Service />
      <CompleteSection />
      <Analysis />
      <FAQ />
      <Blog />
      <Team />
      <Contact />
    </>
  )
}

export default HomePage
