import React, { FC } from 'react'
import { HashLink } from 'react-router-hash-link'

const Service: FC = () => {
  return (
    <section
      className="services-section services-shape bg-img bg-top-style ptb-100"
      data-background="./assets/images/services/services-bg.png"
    >
      <div id="service" className="container">
        <div className="main-section-title white-title">
          <span className="top-title">NOS SERVICES</span>
          <h2>
            Nous offrons des services de qualité pour accompagner nos clients
          </h2>
        </div>

        <div className="services-slide owl-carousel owl-theme">
          <div className="main-services-item">
            <div className="icon">
              <img src="assets/images/icon/automation.png" alt="automation" />
            </div>
            <h3>
              <HashLink to="/services#serviceexpertisecomptable">
                Expertise comptable
              </HashLink>
            </h3>
            <p>
              Nous réalisons les différentes missions d’expertise comptable. Ces
              interventions concernent, notamment, les travaux traditionnels de
              tenue et de révision de la comptabilité, la présentation des
              comptes annuels, l’établissement des comptes consolidés et des
              situations intermédiaires ...
            </p>
            <HashLink
              to="/services#serviceexpertisecomptable"
              className="main-detail-btn"
            >
              Plus de détails
              <i className="fa-regular fa-circle-right"></i>
            </HashLink>
          </div>

          <div className="main-services-item">
            <div className="icon">
              <img src="assets/images/icon/analysis.png" alt="analysis" />
            </div>
            <h3>
              <HashLink to="/services#serviceaudit">
                Audit et commissariat aux comptes
              </HashLink>
            </h3>
            <p>
              Notre équipe intervient auprès des entités pour réaliser l’audit
              comptable et financier, l’audit opérationnel et l’audit des
              systèmes d’informations. Nous réalisons également l’audit légal
              des comptes
            </p>
            <HashLink to="/services#serviceaudit" className="main-detail-btn">
              Plus de détails
              <i className="fa-regular fa-circle-right"></i>
            </HashLink>
          </div>

          <div className="main-services-item">
            <div className="icon">
              <img src="assets/images/icon/learning.png" alt="learning" />
            </div>
            <h3>
              <HashLink to="/services#servicecontroleinterne">
                Contrôle interne
              </HashLink>
            </h3>
            <p>
              Nous proposons à nos clients des missions diversifiées relatives à
              la maîtrise de leur activité ; allant de la mise en place, de
              l’évaluation au renforcement des dispositifs du contrôle interne.
              Nous leurs assistons dans les travaux de rédaction de manuels.
            </p>
            <HashLink
              to="/services#servicecontroleinterne"
              className="main-detail-btn"
            >
              Plus de détails
              <i className="fa-regular fa-circle-right"></i>
            </HashLink>
          </div>

          <div className="main-services-item">
            <div className="icon">
              <img src="assets/images/icon/automation.png" alt="automation" />
            </div>
            <h3>
              <HashLink to="/services#servicejurique">Juridique</HashLink>
            </h3>
            <p>
              Vos travaux quotidiens sont liés au cadre juridique de votre
              activité. Il est donc indispensable de comprendre votre
              environnement pour les fins d’analyse et de détermination des
              conséquences juridiques de vos décisions et opérations.
            </p>
            <HashLink to="/services#servicejurique" className="main-detail-btn">
              Plus de détails
              <i className="fa-regular fa-circle-right"></i>
            </HashLink>
          </div>

          <div className="main-services-item">
            <div className="icon">
              <img src="assets/images/icon/analysis.png" alt="analysis" />
            </div>
            <h3>
              <HashLink to="/services#servicesocial">
                Social et ressources humaines
              </HashLink>
            </h3>
            <p>
              En plus de l’obligation de respecter la législation et la
              réglementation du travail, vous devrez vous assurer de
              l’harmonisation de vos pratiques en matière des ressources
              humaines
              <br />
              <br />
            </p>
            <HashLink to="/services#servicesocial" className="main-detail-btn">
              Plus de détails
              <i className="fa-regular fa-circle-right"></i>
            </HashLink>
          </div>

          <div className="main-services-item">
            <div className="icon">
              <img src="assets/images/icon/learning.png" alt="learning" />
            </div>
            <h3>
              <HashLink to="/services#servicefiscal">Fiscal</HashLink>
            </h3>
            <p>
              La législation et la réglementation fiscale évoluent, vous devrez
              vous assurer de la correcte réalisation de vos opérations en
              effectuant des choix fiscaux les plus optimisant.
              <br />
              <br />
            </p>
            <HashLink to="/services#servicefiscal" className="main-detail-btn">
              Plus de détails
              <i className="fa-regular fa-circle-right"></i>
            </HashLink>
          </div>

          <div className="main-services-item">
            <div className="icon">
              <img src="assets/images/icon/analysis.png" alt="analysis" />
            </div>
            <h3>
              <HashLink to="/services#servicebudget">Budget</HashLink>
            </h3>
            <p>
              Le chiffrage de vos objectifs est un préalable à la maîtrise de
              votre activité. Nos intervenants vous accompagnent à mieux les
              définir en vue de préparer et suivre vos budgets
              <br />
              <br />
            </p>
            <HashLink to="/services#servicebudget" className="main-detail-btn">
              Plus de détails
              <i className="fa-regular fa-circle-right"></i>
            </HashLink>
          </div>

          <div className="main-services-item">
            <div className="icon">
              <img src="assets/images/icon/learning.png" alt="learning" />
            </div>
            <h3>
              <HashLink to="/services#servicetransmission">
                Transmission d’entreprises
              </HashLink>
            </h3>
            <p>
              Nous vous assistons et conseillons durant toutes les étapes
              importantes des opérations d’acquisition ou de cession
              d’entreprise.
              <br />
              <br />
              <br />
            </p>
            <HashLink
              to="/services#servicetransmission"
              className="main-detail-btn"
            >
              Plus de détails
              <i className="fa-regular fa-circle-right"></i>
            </HashLink>
          </div>

          <div className="main-services-item">
            <div className="icon">
              <img src="assets/images/icon/analysis.png" alt="analysis" />
            </div>
            <h3>
              <HashLink to="/services#serviceformation">Formation</HashLink>
            </h3>
            <p>
              Nous vous proposons de vous accompagner dans l’identification de
              vos besoins de formation, la conception et la mise en œuvre de vos
              plans de formation
            </p>
            <HashLink
              to="/services#serviceformation"
              className="main-detail-btn"
            >
              Plus de détails
              <i className="fa-regular fa-circle-right"></i>
            </HashLink>
          </div>

          <div className="main-services-item">
            <div className="icon">
              <img src="assets/images/icon/learning.png" alt="learning" />
            </div>
            <h3>
              <HashLink to="/services#servicestatiques">
                Études économiques et statistiques
              </HashLink>
            </h3>
            <p>
              Nous effectuons des études de marché et de faisabilité des projets
              d’investissements envisagés en République de Guinée ou à
              l’étranger. Ces études sont en général accompagnées des
              interventions ...
            </p>
            <HashLink
              to="/services#servicestatiques"
              className="main-detail-btn"
            >
              Plus de détails
              <i className="fa-regular fa-circle-right"></i>
            </HashLink>
          </div>
        </div>
      </div>

      <img
        src="assets/images/services/services-shape-1.png"
        className="shape shape-1"
        alt="services-shape-1"
      />
      <img
        src="assets/images/services/services-shape-2.png"
        className="shape shape-2"
        alt="services-shape-2"
      />
    </section>
  )
}

export default Service
