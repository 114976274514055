import React, { FC } from 'react'

const Analysis: FC = () => {
  return (
    <section
      className="analysis-section bg-img"
      data-background="./assets/images/analysis/analysis-bg.jpg"
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="analysis-img" data-cues="slideInLeft">
              <img
                src="assets/images/analysis/saa-analysis-img-2.jpg"
                alt="analysis-img"
                width="680px"
                height="640px"
              />
            </div>
          </div>

          <div className="col-lg-6">
            <div className="analysis-content pt-100 pb-70">
              <span className="top-title">NOMBRE D'INTERVENANTS</span>
              <h2>
                La satisfaction de nos clients au coeur de nos préoccupations
              </h2>
              <div
                className="row justify-content-center"
                data-cues="slideInDown"
              >
                <div className="col-lg-6 col-sm-6">
                  <div className="main-completed-item d-flex align-items-center">
                    <div className="icon">
                      <img
                        src="assets/images/icon/analysis.png"
                        alt="analysis"
                      />
                    </div>
                    <div className="completed-content">
                      <h3>390+</h3>
                      <span>Missions d'audit</span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-sm-6">
                  <div className="main-completed-item d-flex align-items-center">
                    <div className="icon">
                      <img
                        src="assets/images/icon/satisfied.png"
                        alt="satisfied"
                      />
                    </div>
                    <div className="completed-content">
                      <h3>120+</h3>
                      <span>Clients satisfaits</span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-sm-6">
                  <div className="main-completed-item d-flex align-items-center">
                    <div className="icon">
                      <img src="assets/images/icon/skilled.png" alt="skilled" />
                    </div>
                    <div className="completed-content">
                      <h3>300+</h3>
                      <span>Formations</span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-sm-6">
                  <div className="main-completed-item d-flex align-items-center">
                    <div className="icon">
                      <img src="assets/images/icon/media.png" alt="media" />
                    </div>
                    <div className="completed-content">
                      <h3>25+</h3>
                      <span>Collaborateurs</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Analysis
