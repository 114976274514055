import React, { FC } from 'react'
import { HashLink } from 'react-router-hash-link'

const Blog: FC = () => {
  return (
    <section className="blog-section blog-shape pt-100 pb-70">
      <div id="blog" className="container">
        <div className="main-section-title">
          <span className="top-title">NOS ARTICLES</span>
          <h2>Pour mieux informer nos clients, voici quelques articles</h2>
        </div>

        <div className="row justify-content-center" data-cues="slideInDown">
          <div className="col-lg-4 col-md-6">
            <div className="main-blog-item">
              <HashLink to="#blog-detail" className="d-block">
                <img
                  src="assets/images/blog/ssaa-blog1.jpg"
                  alt="blog-1"
                  width="860px"
                  height="600px"
                />
              </HashLink>

              <div className="main-blog-content hover-style">
                <div className="inner-border">
                  <ul>
                    <li>
                      <HashLink to="#blog-detail">
                        <i className="fa-light fa-user"></i>
                        Mamady Camara
                      </HashLink>
                    </li>
                    <li>
                      <HashLink to="#blog-detail">
                        <i className="fa-light fa-calendar-days"></i>3 Jan, 2024
                      </HashLink>
                    </li>
                  </ul>

                  <h3>
                    <HashLink href="#blog-detail">
                      la rétroactivité de la loi fiscale
                    </HashLink>
                  </h3>
                  <p>
                    En cas de "grande" rétroactivité, la disposition législative
                    fiscale est, en principe, contraire à la Constitution,..
                  </p>

                  <HashLink to="#blog-detail" className="main-detail-btn">
                    Lire
                  </HashLink>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="main-blog-item">
              <HashLink to="#glog-detail" className="d-block">
                <img
                  src="assets/images/blog/ssaa-blog2.jpeg"
                  alt="blog-2"
                  width="860px"
                  height="600px"
                />
              </HashLink>

              <div className="main-blog-content hover-style">
                <div className="inner-border">
                  <ul>
                    <li>
                      <HashLink to="#blog-detail">
                        <i className="fa-light fa-user"></i>
                        Mamady Camara
                      </HashLink>
                    </li>
                    <li>
                      <HashLink href="#blog-detail">
                        <i className="fa-light fa-calendar-days"></i>
                        16 Feb, 2024
                      </HashLink>
                    </li>
                  </ul>

                  <h3>
                    <HashLink to="#blog-detail">
                      S'occuper de vos impôts n'aura jamais été aussi facile
                    </HashLink>
                  </h3>
                  <p>
                    Depuis la mise en place du prélèvement à la source, le
                    paiement de votre impôt sur le revenu est facilité ...
                    <br />
                    <br />
                  </p>

                  <HashLink to="#blog-detail" className="main-detail-btn">
                    Lire
                  </HashLink>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="main-blog-item">
              <HashLink to="#blog-detail" className="d-block">
                <img
                  src="assets/images/blog/ssaa-blog3.jpeg"
                  alt="blog-3"
                  width="860px"
                  height="600px"
                />
              </HashLink>

              <div className="main-blog-content hover-style">
                <div className="inner-border">
                  <ul>
                    <li>
                      <HashLink to="#blog-detail">
                        <i className="fa-light fa-user"></i>
                        Mamady Camara
                      </HashLink>
                    </li>
                    <li>
                      <HashLink to="blog-detail">
                        <i className="fa-light fa-calendar-days"></i>
                        13 Jan, 2024
                      </HashLink>
                    </li>
                  </ul>

                  <h3>
                    <HashLink to="#blog-detail">
                      La formation du personnel, une clé de croissance pour
                      votre entreprise
                    </HashLink>
                  </h3>
                  <p>
                    La formation du personnel est l’un des aspects les plus
                    décisifs pour le succès de toute entreprise ...
                  </p>

                  <HashLink to="#blog-detail" className="main-detail-btn">
                    Lire
                  </HashLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <img
        src="assets/images/blog/blog-shape-1.png"
        className="blog-shape-1"
        alt="blog-shape-1"
      />
      <img
        src="assets/images/blog/blog-shape-2.png"
        className="blog-shape-2"
        alt="blog-shape-2"
      />
    </section>
  )
}

export default Blog
