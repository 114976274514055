import React, { FC } from 'react'
import { CompagnyServiceType } from 'src/type/type'

const CompagnyService: FC<CompagnyServiceType> = ({
  imageSrc,
  title,
  description,
  alter,
}) => {
  return (
    <div className="col-lg-3 col-sm-6">
      <div className="main-company-item hover-style">
        <div className="inner-border">
          <div className="icon">
            <img src={imageSrc} alt={alter} />
          </div>
          <h3>{title}</h3>
          <p>{description}</p>
        </div>
      </div>
    </div>
  )
}

export default CompagnyService
