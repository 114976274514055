import React, { FC } from 'react'
import { HashLink } from 'react-router-hash-link'

const Navbar: FC = () => {
  return (
    <div className="navbar-section">
      <div className="main-nav">
        <div className="container">
          <nav className="navbar navbar-expand-md navbar-light">
            <HashLink className="navbar-brand" to="/home">
              <img src="assets/images/logo/logo.png" alt="Logo" />
            </HashLink>
            <div
              className="collapse navbar-collapse for-mobile-menu"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav m-auto">
                <li className="nav-item">
                  <HashLink to="/home" className="nav-link">
                    Home
                  </HashLink>
                </li>
                <li className="nav-item">
                  <HashLink to="/services" className="nav-link">
                    Nos services
                  </HashLink>
                </li>

                <li className="nav-item">
                  <HashLink to="/home#about" className="nav-link">
                    Qui sommes-nous ?
                  </HashLink>
                </li>
                <li className="nav-item">
                  <HashLink to="/home#blog" className="nav-link">
                    Actualités
                  </HashLink>
                </li>
                <li className="nav-item">
                  <HashLink to="/team#team-member" className="nav-link">
                    Notre Equipe
                  </HashLink>
                </li>
                <li className="nav-item">
                  <HashLink to="/home#contact" className="nav-link">
                    Nous contacter
                  </HashLink>
                </li>
              </ul>

              <div className="nav-right-options">
                <ul>
                  <li>
                    <HashLink to="/home#testimonial" className="main-btn">
                      <span>
                        <span className="get">Temoignages</span>
                        <i className="fa-solid fa-arrow-right"></i>
                      </span>
                    </HashLink>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>

      <div className="mobile-nav">
        <div className="container">
          <div className="mobile-menu">
            <div className="logo">
              <a href="index.html">
                <img src="assets/images/logo/logo.png" alt="Logo" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar
