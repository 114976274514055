import React, { FC } from 'react'
import { HashLink } from 'react-router-hash-link'

const CompleteSection: FC = () => {
  return (
    <section className="complete-section pb-100" data-cues="slideInDown">
      <div className="container">
        <div className="complete-content-wrapper">
          <div className="row align-items-center">
            <div className="col-lg-8 col-md-8">
              <div className="complete-content">
                <h3>
                  Nous vous garantissons un partenariat durable et porteur de
                  valeur sûre et réelle
                </h3>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 text-lg-end text-md-end">
              <HashLink to="#projects" className="main-btn">
                <span>
                  Plus de détails
                  <i className="fa-solid fa-arrow-right"></i>
                </span>
              </HashLink>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CompleteSection
