import React, { FC } from 'react'
import Banner from 'src/components/Banner/Banner copy'
import Header from 'src/components/Header/Header'
import { HashLink } from 'react-router-hash-link'
import Navbar from 'src/components/Navbar/Navbar'

const ServicesPage: FC = () => {
  const s1 = document.createElement('script')
  s1.src = 'assets/js/jquery.min.js'
  s1.async = true

  const s2 = document.createElement('script')
  s2.src = 'assets/js/bootstrap.bundle.min.js'
  s2.async = true

  const s3 = document.createElement('script')
  s3.src = 'assets/js/jquery.meanmenu.js'
  s3.async = true

  const s4 = document.createElement('script')
  s4.src = 'assets/js/owl.carousel.min.js'
  s4.async = true

  const s5 = document.createElement('script')
  s5.src = 'assets/js/magnific-popup.min.js'
  s5.async = true

  const s6 = document.createElement('script')
  s6.src = 'assets/js/counterup.min.js'
  s6.async = true

  const s7 = document.createElement('script')
  s7.src = 'assets/js/waypoints.min.js'
  s7.async = true

  const s8 = document.createElement('script')
  s8.src = 'assets/js/scrollcue.js'
  s8.async = true

  const s9 = document.createElement('script')
  s9.src = 'assets/js/countdown.min.js'
  s9.async = true

  const s10 = document.createElement('script')
  s10.src = 'assets/js/custom.js'
  s10.async = true

  document.body.appendChild(s1)
  document.body.appendChild(s2)
  document.body.appendChild(s3)
  document.body.appendChild(s4)
  document.body.appendChild(s5)
  document.body.appendChild(s6)
  document.body.appendChild(s7)
  document.body.appendChild(s8)
  document.body.appendChild(s9)
  document.body.appendChild(s10)
  return (
    <>
      <Header />
      <Navbar />
      <Banner />
      <div className="terms-conditions-section pb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 pt-100">
              <div
                id="list-example"
                className="privacy-and-conditions-list me-15"
              >
                <HashLink
                  className="nav-link"
                  to="/services#serviceexpertisecomptable"
                >
                  Expertise comptable
                </HashLink>
                <HashLink className="nav-link" to="/services#serviceaudit">
                  Audit et commissariat aux comptes
                </HashLink>
                <HashLink
                  className="nav-link"
                  to="/services#servicecontroleinterne"
                >
                  Contrôle interne
                </HashLink>
                <HashLink className="nav-link" to="/services#servicejurique">
                  Juridique
                </HashLink>
                <HashLink className="nav-link" to="/services#servicesocial">
                  Social et ressources humaines
                </HashLink>
                <HashLink className="nav-link" to="/services#servicefiscal">
                  Fiscal
                </HashLink>
                <HashLink className="nav-link" to="/services#servicebudget">
                  Budget
                </HashLink>
                <HashLink
                  className="nav-link"
                  to="/services#servicetransmission"
                >
                  Transmission d’entreprises
                </HashLink>
                <HashLink className="nav-link" to="/services#serviceformation">
                  Formation
                </HashLink>
                <HashLink className="nav-link" to="/services#servicestatiques">
                  Études économiques et statistiques
                </HashLink>
                <HashLink
                  className="nav-link"
                  to="/services#servicerepresentationsociete"
                >
                  Représentation des sociétés
                </HashLink>
              </div>
            </div>

            <div className="col-lg-8">
              <div className="ms-15">
                <div className="pt-100" id="serviceexpertisecomptable">
                  <div className="privacy-and-conditions-item">
                    <h3>Expertise comptable</h3>
                    <p>
                      Nous réalisons les différentes missions d’expertise
                      comptable. Ces interventions concernent, notamment, les
                      travaux traditionnels de tenue et de révision de la
                      comptabilité, la présentation des comptes annuels,
                      l’établissement des comptes consolidés et des situations
                      intermédiaires. Nous présentons les comptes consolidés en
                      normes françaises et internationales.
                    </p>
                    <p>
                      SANOUSSY ASSOCIES accorde à chaque client des traitements
                      adaptés à sa situation, garantis par la qualité de ses
                      conseils en vue d’assurer une sécurité maximale des
                      informations produites et des services fournis.
                    </p>
                  </div>
                </div>

                <div className="pt-100" id="serviceaudit">
                  <div className="privacy-and-conditions-item">
                    <h3>Audit et commissariat aux comptes</h3>
                    <p>
                      Notre équipe intervient auprès des entités pour réaliser
                      l’audit comptable et financier, l’audit opérationnel et
                      l’audit des systèmes d’informations.
                    </p>
                    <p>
                      Nous réalisons également l’audit légal des comptes des
                      entités privées ou publiques.
                    </p>
                    <p>
                      Le Cabinet SANOUSSY ASSOCIES intervient sur des missions
                      exceptionnelles concernant, entre autres, commissariat aux
                      apports, commissariat à la fusion, commissariat à la
                      transformation.
                    </p>
                  </div>
                </div>

                <div className="pt-100" id="servicecontroleinterne">
                  <div className="privacy-and-conditions-item">
                    <h3>Contrôle interne</h3>
                    <p>
                      Nous proposons à nos clients des missions diversifiées
                      relatives à la maîtrise de leur activité ; allant de la
                      mise en place, de l’évaluation au renforcement des
                      dispositifs du contrôle interne.
                    </p>
                    <p>
                      Nous leurs assistons dans les travaux de rédaction de
                      manuels de procédures administratives, financières et
                      comptables. Aussi, d’identification et d’implémentation
                      des outils de gestion des risques comme la cartographie
                      des risques.
                    </p>
                  </div>
                </div>

                <div className="pt-100" id="servicejurique">
                  <div className="privacy-and-conditions-item">
                    <h3>Juridique</h3>
                    <p>
                      Vos travaux quotidiens sont liés au cadre juridique de
                      votre activité. Il est donc indispensable de comprendre
                      votre environnement pour les fins d’analyse et de
                      détermination des conséquences juridiques de vos décisions
                      et opérations.
                    </p>
                    <p>
                      Le cabinet SANOUSSY ASSOCIES vous assiste et conseille
                      lors les opérations liées à vos entreprises et à la vie
                      des affaires.
                    </p>
                    <ul>
                      <li>
                        <i className="icofont-check-circled"></i>
                        études de faisabilité et plans d’affaires ;
                      </li>
                      <li>
                        <i className="icofont-check-circled"></i>
                        choix de forme juridique d’exercice de votre activité ;
                      </li>
                      <li>
                        <i className="icofont-check-circled"></i>
                        réalisation des formalités administratives ;
                      </li>
                      <li>
                        <i className="icofont-check-circled"></i>
                        Transmissions d’entreprises, apports en société, etc.
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="pt-100" id="servicesocial">
                  <div className="privacy-and-conditions-item">
                    <h3>Social et ressources humaines</h3>
                    <p>
                      En plus de l’obligation de respecter la législation et la
                      réglementation du travail, vous devrez vous assurer de
                      l’harmonisation de vos pratiques en matière des ressources
                      humaines.
                    </p>
                    <p>
                      Le cabinet SANOUSSY ASSOCIES vous accompagne pour
                      sécuriser vos opérations et votre personnel (contrat de
                      travail, paie, assistance à la rupture, audit social,
                      etc..).
                    </p>
                  </div>
                </div>
                <div className="pt-100" id="servicefiscal">
                  <div className="privacy-and-conditions-item">
                    <h3>Fiscal</h3>
                    <p>
                      La législation et la réglementation fiscale évoluent, vous
                      devrez vous assurer de la correcte réalisation de vos
                      opérations en effectuant des choix fiscaux les plus
                      optimisant.
                    </p>
                    <p>
                      Nous vous accompagnons à mieux maîtriser les risques
                      fiscaux liés à votre activité.
                    </p>
                  </div>
                </div>
                <div className="pt-100" id="servicebudget">
                  <div className="privacy-and-conditions-item">
                    <h3>Budget</h3>
                    <p>
                      Le chiffrage de vos objectifs est un préalable à la
                      maîtrise de votre activité. Nos intervenants vous
                      accompagnent à mieux les définir en vue de préparer et
                      suivre vos budgets.
                    </p>
                    <p>
                      Chaque mission s’inscrit dans une dynamique de réalisation
                      des stratégies d’entreprise de nos clients par
                      l’identification et la mise en œuvre des outils de
                      contrôle de gestion adaptés.
                    </p>
                  </div>
                </div>
                <div className="pt-100" id="servicetransmission">
                  <div className="privacy-and-conditions-item">
                    <h3>Transmission d’entreprises</h3>
                    <p>
                      Nous vous assistons et conseillons durant toutes les
                      étapes importantes des opérations d’acquisition ou de
                      cession d’entreprise.
                    </p>
                    <ul>
                      <li>
                        <i className="icofont-check-circled"></i>
                        due diligence ;
                      </li>
                      <li>
                        <i className="icofont-check-circled"></i>
                        évaluation d&#39;entreprise ;
                      </li>
                      <li>
                        <i className="icofont-check-circled"></i>
                        intégration d’associés et rapprochement d’entreprises ;
                      </li>
                      <li>
                        <i className="icofont-check-circled"></i>
                        aide à la transmission de votre entreprise.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="pt-100" id="serviceformation">
                  <div className="privacy-and-conditions-item">
                    <h3>Formation</h3>
                    <p>
                      Nous vous proposons de vous accompagner dans
                      l’identification de vos besoins de formation, la
                      conception et la mise en œuvre de vos plans de formation.
                    </p>
                    <p>
                      Nos intervenants veillent à la cohérence avec vos
                      stratégies d’entreprises, des formations qu’ils proposent
                      pour perfectionner vos équipes.
                    </p>
                  </div>
                </div>
                <div className="pt-100" id="servicestatiques">
                  <div className="privacy-and-conditions-item">
                    <h3>Études économiques et statistiques</h3>
                    <p>
                      Nous effectuons des études de marché et de faisabilité des
                      projets d’investissements envisagés en République de
                      Guinée ou à l’étranger. Ces études sont en général
                      accompagnées des interventions d’élaboration des plans
                      d’affaires.
                    </p>
                    <p>
                      Nos intervenants vous assistent à réaliser les enquêtes et
                      les sondages afin de déterminer les impacts de votre
                      activité sur les communautés environnantes.
                    </p>
                    <p>
                      Nous intervenons également sur des aspects liés à la
                      responsabilité sociétale des entreprises (RSE) et au
                      reporting de durabilité (CSRD).
                    </p>
                  </div>
                </div>
                <div className="pt-100" id="servicerepresentationsociete">
                  <div className="privacy-and-conditions-item">
                    <h3>Représentation des sociétés</h3>
                    <p>
                      Vous êtes une entité en phase d’installation ou vous ne
                      disposez pas de personnel et vous souhaitez confier vos
                      démarches administratives à un cabinet, SANOUSSSY ASSOCIES
                      vous accompagne avec efficacité pour atteindre vos
                      objectifs auprès des autorités compétentes et des sociétés
                      partenaires.
                    </p>
                    <p>
                      Les prestations de représentation dont nous vous proposons
                      sont diversifiées et font l’objet de convention adaptée
                      aux besoins de chaque client.
                    </p>
                    <p>
                      Nos intervenants vous assurent des prestations de qualité,
                      dans le respect de nos normes du travail.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ServicesPage
