import React, { FC } from 'react'
import { HashLink } from 'react-router-hash-link'

const Banner: FC = () => {
  return (
    <section
      className="banner-section bg-img pt-50"
      data-background="./assets/images/banner/saa-bg-1.jpeg"
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div
              className="banner-content"
              data-cues="fadeIn"
              data-group="images"
            >
              <span className="top-title">Expertise comptable et Audit</span>
              <h1>Ajouter du rythme à votre entreprise</h1>
              <p>
                Satisfaction client au centre de nos préoccupations et
                compréhension de vos besoins
              </p>

              <div className="banner-btn-group">
                <HashLink to="#contact" className="main-btn">
                  <span className="">
                    Nous écrire
                    <i className="fa-solid fa-arrow-right"></i>
                  </span>
                </HashLink>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="banner-img" data-cues="fadeIn" data-group="images">
              <img
                src="assets/images/banner/saa-img-1-3.png"
                alt="banner-img"
              />
            </div>
          </div>
        </div>
        <br />
      </div>
    </section>
  )
}

export default Banner
